
<template>
  <div id="all-flowers"  >
    <h2>בית הספר לסיעוד שערי צדק מזמין אתכם להצטרף ולהודות לאח ולאחות הגיבורים שלכם</h2>
    <flower-card  v-for="flower in getFlowers" v-bind:key="flower.id" :flower="flower" ></flower-card>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import FlowerCard from './FlowerCard.vue';

export default {
  components: { FlowerCard,
                },
  data() {
    return {
    }
  },
  mounted() {
      this.zeroAnimationCounter();
  },
  computed: {
    ...mapState(['flowers']),
    getFlowers() {
      return this.flowers;
    },

  },
  methods: {
      ...mapActions(['zeroAnimationCounter']),
  }

}
</script>

<style>
#all-flowers {
    width: 90vw;
    height: 90vh;
    background: white;
    margin: 20px auto 0 auto;
    direction: rtl;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}
h2 {
    text-align: center;
    margin: 0 auto;
}

@media screen and (max-width:1500px) {
    h2{font-size: 31px!important;}
}
@media screen and (max-width:1200px) {
    h2{font-size: 29px!important;}
}
@media screen and (max-width:768px) {
    h2{font-size: 27px!important;}
}
</style>
