import axios from 'axios'
export const storeObj = {
    state: {
        baseApi: 'https://nurse.dev.org.il/api',
        // baseApi: 'https://localhost:8000/api',
        menuBarStatus: false,
        animationCounter: 0,
        flowers: [],
        searchResults: [],
        currentFlower: {},
        latestFlower: 0,
        firstFlower: 0,
    },

    mutations: {
        increment(state) {
            state.animationCounter++
        },
        zero(state) {
            state.animationCounter = 0;
        },
        toggleMenuBar(state) {
            state.menuBarStatus = !state.menuBarStatus
        },
        insertFlowers(state, data = []) {
            state.flowers = data
        },
        updateHighest(state, number) {
            state.latestFlower = number
        },
        updateLowest(state, number) {
            state.firstFlower = number
        },
        setCurrentFlower(state, flowerObj) {
            state.currentFlower = flowerObj;
        },
        setSearchResults(state, flowers) {
            state.searchResults = flowers;
        }
    },
    actions: {
        getFlowers({ commit, state }, payload = { direction: 'up' }) {
            commit('zero');
            let vars = '';
            vars += state.firstFlower > 0 ? `&first=${state.firstFlower}` : '';
            vars += state.lastFlower > 0 ? `&latest=${state.latestFlower}` : '';
            axios.get(`${state.baseApi}/flowers?direction=${payload.direction}${vars}`).then((res) => {
                if (payload.direction === 'up' && res.data[0]) {
                    commit('updateHighest', res.data[0].id)
                }
                if (payload.direction === 'down' && res.data[0]) {
                    commit('updateLowest', res.data[res.data.length - 1].id)
                }
                commit('insertFlowers', res.data)
            })
        },
        getSpecificFlower({ commit, state }, flowerID) {
            commit('setCurrentFlower', {});

            if (state.flowers && state.flowers.length > 0) {
                let flower = state.flowers.find(flower => flower.id === parseInt(flowerID));
                if (flower.id) {
                    commit('setCurrentFlower', flower);
                    return flower;
                }
            }
            axios.get(`${state.baseApi}/flowers/${flowerID}`).then((res) => {
                commit('setCurrentFlower', res.data);
                return res.data;
            })
        },
        sendNewFlower({ commit, state, dispatch }, flowerObj = {}) {
            axios.post(`${state.baseApi}/flowers`, flowerObj).then((res) => {
                commit('insertFlowers', []);
                dispatch('getFlowers');
                commit('setCurrentFlower', res.data);
            }).catch((err) => {})
        },
        incAnimationCounter({ commit }) {
            commit('increment');
        },
        zeroAnimationCounter({ commit }) {
            commit('zero');

        },
        changeMenuStatus({ commit }) {
            commit('toggleMenuBar');
        },
        searchFlower({ commit, state }, searchInput = '') {
            axios.get(`${state.baseApi}/flowers?q=${searchInput}`).then((res) => {
                commit('setSearchResults', res.data);
            })
        }
    }
}