import AllFlowers from "./components/AllFlowers.vue"
import NewFlower from "./components/NewFlower.vue"
import DisplayFlower from "./components/DisplayFlower.vue"
import SearchFlower from "./components/SearchFlower.vue"

export const routesObj = [{
    path: '/',
    component: AllFlowers,
    name: 'all-flowers'
}, {
    path: '/flower/new',
    component: NewFlower,
    name: 'new-flower'
}, {
    path: '/flower/search',
    component: SearchFlower,
    name: 'search-flower'
}, {
    path: '/flower/:id',
    component: DisplayFlower,
    name: 'show-flower-by-id'
}]