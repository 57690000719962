<template>
  <div id="display-flower">
  <div class="icons">
    <div class="icon fb">
        <a :href="getFbLink">
            <i class="fab fa-facebook-square"></i>
        </a>
    </div>
    <div class="icon wu">
        <a :href="getWuLink">
            <i class="fab fa-whatsapp-square"></i>
        </a>
    </div>
    <br />
        שתפו!
  </div>
  <div class="add-new">
    <button class="btn btn-success" @click="addNew()"><i class="fas fa-plus-square"></i> שילחו ברכה משלכם</button>
  </div>
    <div class="row" v-if="flower">
        <div class="col-12 ">
            <flower-card :flower="flower" display="full"></flower-card>
        </div>
    </div>


  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import FlowerCard from './FlowerCard.vue';
export default{
  components: { FlowerCard },
    name:'display-flower',
    computed: {
        ...mapState(['currentFlower']),
        flower() {
            return this.currentFlower;
        },
        getWuLink() {
            return `https://wa.me?text=https://nurse.dev.org.il${this.$route.fullPath}`;
        },
        getFbLink() {
            return `https://facebook.com/sharer/sharer.php?kid_directed_site=0&u=https://nurse.dev.org.il${this.$route.fullPath}&display=popup&ref=plugin&src=share_button`;
        },
        getName() {
            return this.flower && this.flower.nurseName ? this.flower.nurseName : ''
        },
    },
    methods: {
        ...mapActions(['getSpecificFlower'] ),
        addNew() {
            this.$router.push({name:'new-flower'});
        }
    },
    mounted() {
        if(!this.currentFlower || !this.currentFlower.id || (this.currentFlower.id !== this.$route.params.id))
            this.getSpecificFlower(this.$route.params.id);
    },
    metaInfo() {
        return {
            title: `${this.getName} - יום האחות הבינלאומי- תעודת הערכה`,
            meta: [
                {'og:title': `${this.getName}, ${this.getSender} שלח/ה לך פרח`},
                {'og:url': `https://nurse.dev.org.il${this.$route.fullPath}`},
                {'og:type': 'website'},
                {'og:description': `הוסיפו ברכה משלכם ושילחו לאח/ות שאתם מעריכים`},
                {'og:image': 'https://nurse.dev.org.il/img/fbbg.jpg'}
                ]

        }
    }


}
</script>
<style scoped>
#display-flower .row {
    height: 70vh;
    margin-top: 0;
}
.add-new {
    text-align: center;
}
.card-headline{
    background: url('../assets/backgrounds/bg2.jpg');
    background-position: center;
    background-size: cover;
    height: 30vh;
}
.card-headline h1 {
    color: white;
    text-align: center;
    font-size: 45px;
}
.card-body {
    background: url('../assets/backgrounds/display.jpg');
    background-position: center;
    background-size: cover;
    height:30vh;

}
.icons{ text-align: center; }
.icon{
  display: inline;
}
.icon i{
    font-size:80px;
    display: inline;
}

.icon.fb i{
color: #3c5a99;
margin-right: 20px;
}
.icon.wu i{
color: #2fb937;
}
</style>
