<template>
  <div id="navbar">

    <div class="container">

      <div class="row line">
            <router-link :to="{name:'all-flowers'}" :class="{active: checkRoute('all-flowers')}">
              <strong  @click="closeNavbar()">
              <i class="fas fa-table" ></i>
                  צפה בכל הפרחים
              </strong>
          </router-link>
      </div>
      <div class="row line">
            <router-link :to="{name:'new-flower'}" :class="{active: checkRoute('new-flower')}" @click="closeNavbar()">
              <strong @click="closeNavbar()">
              <i class="fas fa-plus-circle"></i>
                  הוספת ברכה חדשה
              </strong>
          </router-link>
      </div>
      <div class="row line">
            <router-link :to="{name:'search-flower'}" :class="{active: checkRoute('search-flower')}" @click="closeNavbar()">
              <strong @click="closeNavbar()">
              <i class="fas fa-search"></i>
                  חיפוש
              </strong>
          </router-link>
      </div>


    </div>
    <div class="d-none container stats">
        <div class="col-12">
        ברכות התווספו עד עכשיו
        </div>
    </div>

<div class="bottom">
    <div class="row">
      <div class="col-12">
        המערכת נבנתה לעילוי נשמת אריאל יהודה צרויה בן מנחם אליהו וצמרת
      </div>
    </div>
      <div class="row">
          <a href="mailto: stzahi@gmail.com">דיווח על באג</a>
      </div>
  </div>
</div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  components: {  },
    name: 'navbar',
    methods: {
        ...mapActions(['changeMenuStatus']),
        checkRoute( inputTxt ) {
            if(this.$route.name === inputTxt) return true;
            return false;
        },
        closeNavbar() {
            this.changeMenuStatus();
        }
    }
}
</script>

<style>
#navbar {
  background: white;
  height: calc( 100vh - 75px);
  position:fixed;
  top:83px;
  width:100vw;
  z-index: 3;
  direction: rtl;
  text-align: right;
  right: -100vw;
  transition: 0.5s all;
}
#navbar .stats {
    margin-top:100px;
}
#navbar.open  {
right: 0;
}
#navbar .bottom {
  position: absolute;
  bottom:0;
  width: 100vw;
  border-top: 1px solid silver
}
#navbar .row.line{
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid silver;
}
#navbar .row{
  border: none;
  display:block;
}
#navbar .bottom .row {
  color: #7f7b7b;
  text-align: center;
  display: block;
  }
  #navbar a {
  color: black
  }
  #navbar .active {
  color:#ff0000
  }

</style>
