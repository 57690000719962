<template>
  <div id="search-flower">
        <nav class="navbar navbar-light bg-light">
            <div class="form-inline">
                <input class="form-control mr-sm-2" type="search" v-model="searchInput" placeholder="מה לחפש?" aria-label="Search">
                <button class="btn btn-outline-success my-2 my-sm-0" type="submit" @click="makeSearch()">חפש</button>
            </div>
        </nav>
        <div id="all-flowers" v-if="isResults">
            <flower-card v-for="flower in getSearchResults" :key="flower.id" :flower="flower"></flower-card>
        </div>
        <div v-if="!isResults">
        אין תוצאות
        </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import FlowerCard from './FlowerCard.vue'
export default {
  components: { FlowerCard },
    name: 'search-flower',
    data() { return {
        searchInput: '',
    }},
    computed: {
        ...mapState(['searchResults']),
        getSearchResults() {
            return this.searchResults;
        },
        isResults() {
            return this.getSearchResults.length>0;
        }
    },
    methods: {
        ...mapActions(['searchFlower']),
        makeSearch() {
            this.searchFlower(this.searchInput);

        }
    }
}
</script>
