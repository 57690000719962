<template>
  <div>

<div class="row">
    <h2>בית הספר לסיעוד שערי צדק מזמין אתכם להצטרף ולהודות לאח ולאחות הגיבורים שלכם</h2>

    <div class="d-none d-md-block col-md-3"></div>
    <div class="col-12 col-md-6 form-container">
            <div>
                <img class="flowers-icon spin-it infinite" src="../assets/flower.svg" />
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">שם האח/ות</label>
                <input v-model="nurseName" type="text" class="form-control" id="name">
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">מקום עבודה</label>
                <input v-model="department" type="text" class="form-control" id="department">
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">מרפאה/בי"ח</label>
                <input v-model="hospital" type="text" class="form-control" id="hospital">
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">תוכן הברכה</label>
                <wysiwyg v-model="myHTML" />
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">מאת</label>
                <input v-model="writer" type="text" class="form-control" id="writer">
            </div>
            <div class="sunflower-container d-none d-md-block">
                <sun-flower></sun-flower>
            </div>
            <button  class="btn btn-primary" @click="sendFlower()">שליחה!</button>

    </div>
</div>

  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import SunFlowerVue from './SunFlower.vue';
export default{
    name: 'new-flower',
    components: {
        'sun-flower': SunFlowerVue
    },
    data() {
        return {
            nurseName: '',
            department: '',
            hospital: '',
            myHTML: '',
            writer: '',

            load: false,
        }
    },
    computed: {
        ...mapState(['currentFlower']),
        isLoading() {
            return this.currentFlower.id && this.load;
        }
    },
    watch: {
        currentFlower(newer) {
            newer.id ? this.$router.push({name: 'show-flower-by-id',params:{id:newer.id}}) : null;

        }
    },
    methods: {
        ...mapActions(['sendNewFlower']),
         sendFlower() {
            let flowerObj = {
                nurseName: this.nurseName,
                department: this.department,
                hospital: this.hospital,
                bless: this.myHTML,
                writer: this.writer,
            }
            this.sendNewFlower(flowerObj)

        }
    }
}
</script>
<style>
.form-container {
    background: rgb(243, 243, 243);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid silver;
}
.infinite {
animation: spin 3s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); opacity:0.75 }
  25% { transform: rotate(90deg); opacity:1 }
  50% { transform: rotate(180deg); opacity:0.75 }
  75% { transform: rotate(270deg); opacity:1 }
  100% { transform:  rotate(360deg); opacity: 0.75 }
}
.form-container .flowers-icon {
    margin: 0 auto;
    position: initial;
    display:block
}
.form-container .editr--content {
background: white
}
.form-container label {
color:#ff0000 ;
}
.sunflower-container {
    position:absolute;
    right:200px
}
</style>
