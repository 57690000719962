<template>
  <div id="app">
      <header-component></header-component>
      <div id="content">
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HeaderVue from './components/Header.vue';

export default {
  name: 'App',
  components: {
    'header-component': HeaderVue
  },
  computed: {
      ...mapState(['menuBarStatus'])
  },
  mounted() {
      this.getFlowers();
  },
  watch: {
      $route(oldRoute,newRoute) {
          if(this.menuBarStatus)
            this.changeMenuStatus();

      }

  },
  methods: {

      ...mapActions(['getFlowers','changeMenuStatus'])
  }
}
</script>

<style>
* {
font-family: 'Alef', sans-serif;
 font-size: 20px;
}
body {
  overflow-x: hidden;
}
#content {
    padding-top: 90px;
    width: 90vw;
    height: 90vh;
    background: white;
    direction: rtl;
    text-align: right;
    margin: 0 auto;
}
.row{
border-top: none!important;
}
</style>
