<template>
  <header id="header">
    <hamburger></hamburger>
    <navbar :class="{open: getMenuBarStatus}"></navbar>
    <img class="main-logo" src="../assets/headerLogo.jpeg" />
    <img class="d-xs-block shhatz-siud" src="../assets/shaatz.png" />
    <img class="nursing-day-logo" src="../assets/nursingDayLogo.jpeg" />
  </header>
</template>
<script>
import { mapState } from 'vuex'
import Hamburger from "./Hamburger.vue"
import Navbar from "./Navbar.vue"
export default {
    name: 'header',
    components: {'hamburger': Hamburger, 'navbar': Navbar},
    data() {
      return {
      }
    },
    computed: {
        ...mapState(['menuBarStatus']),
        getMenuBarStatus() {
            return this.menuBarStatus;
        }
    }
}
</script>

<style>
header {
    width:100vw;
    height: 83px;
    margin: 0 auto;
    border-bottom: 1px solid rgb(226, 224, 224);
    box-shadow: #d4d4d4 0px 1px 9px;
    position: fixed;
    background: white;
    z-index: 3;
}
header img{
    display: block;
    margin: 0 auto;
    max-width: 100vw;
    max-height:100px;
}
.nursing-day-logo{
  position: absolute;
  top:0;
  left:0;
  height:80px;
}
.shhatz-siud {
  position: absolute;
  top:0;
  left: 0;
  height: 80px;
}
@media only screen and (max-width: 1066px) {
  .main-logo {display: none!important;}
  .nursing-day-logo {
    left: 120px;
    margin-top: 11px;
    zoom: 0.7;
      }
  .shhatz-siud {
    zoom: 0.8;
    margin-top: 8px;
}
}
</style>
