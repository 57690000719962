import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import { storeObj } from './store'
import { routesObj } from './routes';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import InstantSearch from 'vue-instantsearch';
import wysiwyg from "vue-wysiwyg";
import VueMeta from 'vue-meta'
import "./../node_modules/vue-wysiwyg/dist/vueWysiwyg.css";

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
});
Vue.use(wysiwyg, { hideModules: { "image": true, "table": true, "code": true, "headings": true, "orderedList": true, "unorderedList": true, "removeFormat": true, "seperator": true, "link": true } }); // config is optional. more below
Vue.use(InstantSearch);
// Make BootstrapVue available throughout your project
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueRouter)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const store = new Vuex.Store(storeObj)
const router = new VueRouter({ routes: routesObj, mode: 'history' });

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
