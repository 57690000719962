<template>
    <div class="flower-card" :class="{full: display==='full'}">
        <router-link :to="{path: `/flower/${flower.id}`}">
            <div class="card-container">
                <img class="flowers-icon" :class="{'spin-it':spinClass, 'infinite': fullCard}" src="../assets/flower.svg" />

                    <p class="nurse-name">תודה לך!</p>

                    <div class="row">
                        <div class="col-sm-5 headline">שם האח/ות</div>
                        <div class="col-sm value">{{flower.nurseName}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 headline">מקום עבודה</div>
                        <div class="col-sm value">{{flower.department}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 headline">מרפאה/בי"ח</div>
                        <div class="col-sm value">{{flower.hospital}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 headline">תוכן הברכה</div>
                        <div class="col-sm text"  v-html="blessToShow"></div>
                    </div>
                    <div class="row sencerely-yours">
                        <div class="col-sm text-left"><span class="headline"> בהערכה רבה, </span>
                        <br />
                        <span class="value">{{flower.writer}} </span></div>
                    </div>
                    <div class="row toolbox" v-if="!fullCard">
                        <div class="col-sm">
                            <i class="fas fa-search"></i>
                            צפה בברכה
                        </div>
                    </div>


            </div>
        </router-link>
    </div>
</template>
<script>
    import {mapActions, mapState} from 'vuex'

export default {
    props: ['flower', 'display'],
    data() {
        return {
            spinClass: false
        }
    },
    created() {
        this.incAnimationTime();
        setTimeout(() =>{this.addSpinClass(); }, this.animationCounter*250)
    },
    computed: {
        ...mapState(['animationCounter']),
        fullCard() {
            return this.display==='full';
        },
        blessToShow() {
            if(this.fullCard) return this.flower.bless;
            let numWords = 6;
            let split =  this.flower && this.flower.bless ? this.flower.bless.split(' ') : [];
            let text = '';
            for (let i=0; i<numWords; i++) {
                if(split[i]){
                    text += split[i];
                    if(i<numWords) text += ' ';
                }
            }
            if(split[numWords]) text = text + "... <p class=\"continue\">(לחץ לקריאת המשך הברכה)</p>";
            return text;
        }
    },
    methods: {
        ...mapActions(['incAnimationCounter']),
    incAnimationTime() {
       this.incAnimationCounter();
    },
        addSpinClass() {
            this.spinClass = true;
        }
    }
}
</script>

<style>
.flower-card > * { color:rgb(63, 63, 63);  font-weight:bold;}
.flower-card > *:hover { color:rgb(63, 63, 63); text-decoration: none; }

.flower-card:hover {
    box-shadow: black;
    opacity: 1;
}
.flower-card {
    color:black;
    background-image: url('../assets/backgrounds/card5.jpg');
    background-size: cover;
    text-align: right;
    border: 1px solid rgb(144, 144, 144);
    padding: 20px;
    display: inline-block;
    position: relative;
    z-index: 2;
    margin-top:30px;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
    width: 100%;
    transition: 0.3s all;
    opacity: 0.8;
}
.flower-card.full {
    max-width: 100%;
    background-image: url('../assets/backgrounds/display.jpg');
}
.row{
    margin: 20px 0;
    border-top: 1px dashed rgb(223, 221, 221);
}
.full .row {
text-align: center;
}
.flower-card .headline {
    font-weight: bold;
    color: rgb(255 76 76);
}
.sencerely-yours {
margin-top:100px;
}
.test {
    animation: 1s  flowerGrow ;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
}
.full .headline {
    font-size: 28px;
}
.full .value {
    font-size: 28px;
}

@keyframes flowerGrow {
    0% { opacity: 1 }
    100% { opacity: 0.5 }
}

.card-container {
    width: 100%;
    height: 100%;
}
.flower-card a{
    text-decoration: none!important;
}
.flowers-icon {
    width:65px;
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0.6;
}
.spin-it {
    animation: growUp 1s linear ;
}
.flowers-icon-bottom {
    position: absolute;
    right: 10px;
    bottom: 0;
    display: block;
    width: 65px;
    height: 65px;
 }
.flowers-icon-bottom svg {
    max-width: 65px;
    max-height: 65px;
}

    .flowers-icon-bottom svg .branch {
        /*animation: growUp 2s ease;*/
    }
    .leafe {

    }
    #flower {
    }

@keyframes growUp {
  0% { transform: scale(1) rotate(0deg); opacity:0.75 }
  50% { transform: scale(2) rotate(180deg); opacity: 1 }
  100% { transform: scale(1) rotate(360deg); opacity: 0.75 }
}

.nurse-name {
    font-weight: bold;
    text-align: center;
    font-size: 30px;
    color: #ff0000;

}
.text:hover {
    color: black;
    text-decoration: none;
}
.text {
  text-overflow: ellipsis;
    width: 100%;
    height: 55px;
}
.continue {
    font-size: 14px;
    color: #636363;
}
.full .text {
    overflow:hidden;
    text-overflow: initial;
    width: 100%;
    height: auto;
    }
.from {
    position: absolute;
    left: 10px;
    bottom: 40px;
    font-size: 24px;
    color: #ff00008c;
    font-weight: bold;
}
.toolbox div {
    padding:0;
    margin:0;
    text-align: center;
}
.toolbox {
    color:#ff000054;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    right: 14px;
    width: 400px;
    margin:0 0 10px 0;

}
@media (min-width: 800px) {
    .flower-card.full {
        font-size: 45px;
    }

}
@media screen and (max-width: 800px) {
    .flower-card.full {
        background-image: url('../assets/backgrounds/card5.jpg');
    }
    .full .sencerely-yours{
    margin-top: 40px;
    }
}
</style>
